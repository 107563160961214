<template>
  <section>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <div>
              <b-card-title></b-card-title>
              <b-card-sub-title></b-card-sub-title>
            </div>
          </div>
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-checkbox v-model="liveReload" name="check-button" @change="liveReloadChange" button
                  button-variant="outline-info">
                  <feather-icon icon="RefreshCwIcon" :badge="updatesCount" badge-classes="badge-info badge-glow"
                    size="21" />
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card-body>

          <template #footer> </template>
        </b-card>
      </b-col>
    </b-row> -->

    <!--  <b-row>
      <b-col>
        <b-button
          variant="flat-secondary"
          class="btn-icon float-right"
          v-b-toggle.sidebar-1
        >
          <feather-icon icon="HelpCircleIcon" size="20" />
        </b-button>
      </b-col>

      <b-sidebar
        id="sidebar-1"
        title="Resultado de Optimizaciones"
        shadow
        bg-variant="dark"
        text-variant="light"
        width="450px"
        right
        backdrop
      >
        <div class="px-2 py-2"></div>
      </b-sidebar>
    </b-row> -->

    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title>Detalle de Mezcla</b-card-title>
              <b-card-sub-title></b-card-sub-title>
            </div>

            <!-- Card Actions -->
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>

          <!-- Card Body  -->
          <b-card-body>
            <div style="display: flow-root;">
              <b-form-checkbox v-model="liveReload" name="check-button" @change="liveReloadChange" button
                button-variant="outline-info">
                <feather-icon icon="RefreshCwIcon" :badge="updatesCount" badge-classes="badge-info badge-glow"
                  size="15" />
              </b-form-checkbox>
              <b-button @click="exportDetalle()"
                style="float:right; margin: 0px 0px 15px 15px; border-color: #ff7f50 !important; background-color: #FF7F50 !important;">
                Descargar detalle
              </b-button>
            </div>
            <ag-grid-vue style="width: 100%; height: 500px" class="ag-theme-alpine" :columnDefs="columnDefs"
              :rowData="rowData" :pinnedTopRowData="pinnedTopRows" @grid-ready="onGridReady"></ag-grid-vue>
          </b-card-body>
        </b-card>
        <b-button @click="goDetalleOptimizacion()"
          style="height: 50px; background-color: #053743 !important; float:right; margin-right: 20px;  margin-top: 20px; padding-top: 17px;">
          Volver a Detalle Optimización</b-button>
      </b-col>
    </b-row>
  </section>
</template> 
          
<script>

import CardTooltip from '@/views/brunacomponents/wrappers/CardTooltipWrapper.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import Stat from '@/views/clients/base/components/Stat.vue'
import Stats from './Stats.vue';
import moment from 'moment';
import HeaderCellRenderer from '@/views/brunacomponents/ag-grid-table/cell-renderers/HeaderCellRenderer.vue';
import { AgGridVue } from "ag-grid-vue";
import axios from '@axios'
import ActionsCellRenderer3 from "@/views/brunacomponents/ag-grid-table/cell-renderers/ActionsCellRender3.vue";
import RepeatStatistics from "@/views/clients/camanchaca/components/RepeatStatistics";

import {
  BTabs,
  BTab,
  BOverlay,
  BSpinner,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormTags,
  BFormCheckboxGroup,
  BFormCheckbox,

  BListGroup,
  BListGroupItem,
  BProgress
} from "bootstrap-vue";
var qs = require('qs');
import useApiServices from '@/services/useApiServices.js';

export default {
  components: {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,
    BrunaElementLoader,
    AgGridTable,
    AgGridVue,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    Stat,
    BFormTags,
    BProgress,
    BListGroup,
    BListGroupItem,
    headerCellRenderer: HeaderCellRenderer,
    CardTooltip,
    Stats,
    BFormCheckboxGroup,
    BFormCheckbox,
    customActionsCellRender3: ActionsCellRenderer3,
    RepeatStatistics
  },
  data() {
    // let optimization_id = this.$route.query.optimization_id;
    // let mezcla_id = this.$route.query.mezcla_id;
    return {
      showOverlay: false,
      liveReload: false,
      updatesCount: '',
      columnDefs: [],
      rowData: [],
      pinnedTopRows: [],
      gridApi: null,
      excelStyles: [],

      defaultColDef: {
        headerValueGetter: (header) => { return this.$t(header.column.userProvidedColDef.headerName) },
      },
      invocation_id: "",
      refreshTime: new Date().getTime(),
      itemsData: [],
    };
  },

  beforeUnmount() {
    this.$pusher.unsubscribe('optimizations');
  },

  beforeRouteLeave(to, from, next) {

    this.$pusher.unsubscribe('optimizations');

    next()
  },
  mounted() {

    this.excelStyles = [
      {
        id: "cell",
        alignment: {
          horizontal: 'Left', vertical: 'Center'
        },
      }
    ];

    let optimization_id = this.$route.query.optimization_id;
    let mezcla_id = this.$route.query.mezcla_id;

    this.refreshData(optimization_id, mezcla_id);

    this.updateCards();
    let self = this;

    //Se escucha a la llegada de las notificaciones de pusher

    var channel = this.$pusher.subscribe('optimizations');

    channel.bind('OptimizationUpdateEvent', function (data) {

      let userData = JSON.parse(localStorage.getItem("userData"));

      if (userData && userData.client.id == data.client_id) {
        let invocation_id = data.invocation_id

        if (optimization_id == invocation_id) {
          if (!self.liveReload) {
            if (self.updatesCount == '') {
              self.updatesCount = 1;
            }
            else {
              self.updatesCount += 1;
            }
          }
          else {
            let toastId = data.content.id
            data.content.component = ToastificationContent;
            self.$toast.dismiss(toastId);
            self.$toast(data.content, { id: toastId });
          }
        }
        if (optimization_id == invocation_id && self.liveReload) {
          self.refreshData(optimization_id, mezcla_id);
        }

      }
    });
  },

  created() {
  },



  methods: {

    //     $optimization_id
    // $mezcla_id
    updateCards() {
      // console.log(this.$route.query.optimization_id);
      let optimization_id = this.$route.query.optimization_id;
      let mezcla_id = this.$route.query.mezcla_id;

      axios.get(useApiServices.detallesMezclaStatistics + "/" + mezcla_id + "/" + optimization_id)
        .then((response) => {

          this.itemsData = response.data;
        })
        .catch((error) => {

        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      const createServerSideDatasource = () => {
        return {
          getRows: this.getRows,
        };
      };

      const updateData = () => {
        // create datasource with a reference to the fake server
        var datasource = createServerSideDatasource();
        // register the datasource with the grid
        params.api.setServerSideDatasource(datasource);
      };

      updateData();

      this.$emit('gridReady', this.gridApi, this.gridColumnApi, params);
    },

    exportDetalle() {
      this.gridApi.exportDataAsExcel({
        fileName: 'detalle_mezcla.xlsx',
      });

    },

    goDetalleOptimizacion() {
      let optimization_id = this.$route.query.optimization_id;

      this.$router.push({
        name: 'molymet-detalle-optimizacion', query: {
          optimization_id: optimization_id
        }
      })
    },

    accionVerDetalles(data) {
      console.log(data)
    },

    liveReloadChange(checked) {

      if (checked) {
        this.updatesCount = ''

        let optimization_id = this.$route.query.optimization_id;
        let mezcla_id = this.$route.query.mezcla_id;
        this.refreshData(optimization_id, mezcla_id);
        this.updateCards();
      }
    },

    refreshData(optimization_id, mezcla_id) {


      let refreshTime = new Date().getTime()

      let query = qs.stringify({
        optimization_id: optimization_id,
        mezcla_id: mezcla_id,
        ts: refreshTime
      })

      axios.request({
        method: "get",
        url: useApiServices.detalleOptimizacion.detalleOptimizacionMezclasTable2 + "?" + query,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          let columnDefs = response.data.columnDefs;
          this.columnDefs = columnDefs;
          this.rowData = response.data.rowData;
          this.pinnedTopRows = response.data.pinnedTopRows;

        })
    },

    elementLoaded(event) {
      console.log("elementLoaded2", event)
    },
  },
};
</script>
      
      
      
        
<style lang="scss" scoped>
 @import "~ag-grid-community/dist/styles/ag-grid.css";
 @import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
          
<style >
  .btn-padding {
    margin-right: 48px;
  }

  .m-estadisticas {
    margin-left: 20px;
    margin-bottom: 1%;
    margin-top: 1%;
  }
  .icon-plan {
    padding-top: 5%;
  }

  .ag-row-pinned {
    background-color: #f0e68c !important;
  }
  </style>
            
          
          <style>
  div.ag-watermark {
    display: none !important;
  }
</style>
        